.footer {
    // .makeStyles-root-1 .MuiCardContent-root .MuiPaper-root {
    //     background-color: transparent ;
    // }
    .contact-grid {
        // background-color: #0B72AC !important;
        background-color: #00B4D8 !important;
        .cont-title {
            color: #fff;
            font-size: 22px;
            font-style: normal;
            line-height: normal;
            font-weight: 400;
        }
        .whats-btn {
            border-radius: 193px;
             border: 1px solid #097ABA;
             background: #3B9ACF;
            color: #FDFDFD;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    .footer-grid {
        background-color: #111010 !important;
        

        .footer-list-title {
            color: #fff;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .footer-list-text {
            color: #fff;

            span {
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                word-wrap: break-word;
            }
        }

        .mail-box {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(-5px, -50%);
            width: 67px;
            height: 51px;
            border-radius: 6px;
            background: #5F3618;
        }

        .MuiFormControl-root {
            width: 100%;
        }

        .f-input-box {
            input {
                width: 100%;
                height: 40px;
            }
        }

        // ul {
        //     padding-left: 15px !important;
        // }

        .MuiGrid-container {
            padding: 25px;
            gap: 30px;

            .MuiGrid-root:not(:last-child) {
                border-right: 1px solid #5F5E5E;
            }
        }

    }

    .whatsapp-icon{
        position: fixed;
        bottom: 66px;
        z-index: 999999;
        left: 20px;
        justify-content: space-around;
        a{
            background: #5bd234;
            display: flex;
            color: #fff;
            font-size: 30px;
            text-align: center;
            border-radius: 10px;
            animation: bounce 1s infinite alternate;
            padding: 5px 10px;
            font-size: 14px;
            font-weight: 700;
            gap: 6px;
            align-items: center;
            text-transform: capitalize;
            min-height: 40px;
            svg{
                width: 32px;
                height: 31px;
            }
        }
     }
     .chatbox-icon{
        bottom: 0px;
        right: 1.5rem;
        position: fixed;
        z-index: 999;
        bottom: 1.5rem;
        button{
            width: 60px;
            height: 60px;
            background-color: #000;
            line-height: 60px;
            border: unset;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            .close-chat{
                color: #FFF;
                width: 40px;
                height: 40px;
            
            }

        }
        
     }
     .chat-form{
        background-color: #f7f7f9;
        position: fixed;
        width: 348px;
        height: 390px;
        padding: 0 16px 15px;
        border-radius: 5px;
        right: 10px;
        overflow: hidden;
        overflow-y: scroll;
        bottom: 7rem;
        z-index: 9;
        &::-webkit-scrollbar {
            width: 6px;
        }
        &::-webkit-scrollbar-track {
            background: #ffffff;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #92929e;
            border-radius: 5px;
        }
        &:before{
            content:'';
            position: absolute;
            top: 0;
            left:0;
            width: 100%;
            height: 90px;
            background-color: #000;
            border-radius: 5px 5px 0px 0px;
        }
        .chat-form-title{
            font-size: 14px;
            color: #fff;
            position: relative;
            line-height: 1.618;
            font-weight: 400;
            text-align: center;
            padding: 16px 0px;
        }
        .chat-scroll{
            height: 349px;
        }
        
      
        .chat-input-box{
            border: 1px solid #d9dbe4;
            border-left: 5px solid #000!important;
            position: relative;
            border-radius: 5px;
            z-index: 1;
            form{
                display: flex;
                flex-wrap: wrap;
                gap: 1rem;
                flex-direction: column;
                input{
                    height: 40px;
                    padding: 0.2rem 1rem 0.2rem;
                    background: #fff;
                    color: #545454;
                    font-size: 13px;
                }
                label{
                    font-size: 13px;
                }
                .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
                    border-color: #d9dbe4;
                    border-radius: 5px;
                }
                .Mui-focused{
                    color: #000;
                    .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
                        border-color: #000;
                        border-width: 1px;
                    }

                }
                button{
                    border-radius: 5px;
                    padding: 0.5rem 1rem;
                    vertical-align: middle;
                    font-size: 1rem;
                    border-color: #000;
                    line-height: 1.5rem;
                    text-align: center;
                    background-color: #000;
                    color: #fff;
                    text-transform: capitalize;
                    gap: 4px;
                }
                .css-1e7oajn-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{
                    border-color: #d32f2f !important;
                }
                .css-eq7m0n-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
                    border-color: #d32f2f !important;
                }
            }
            
        }
     }

    .sub-f-grid {
        background-color: #212121 !important;
        color: #fff;

        .sub-f-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .sub-f-title {
            color: #F9F8F8;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .payment-f-list {
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: end;

            ul {
                padding: 0;
                margin: 0;
            }
        }
    }

    .country-fbox {
        .flag-img {
            width: 30px;
            height: 20px;
        }
    }
    .bottom-footer {
        padding: 10px;
    
        .MuiButtonBase-root.Mui-disabled {
            background-color: #000 !important;
            color: #fff !important;
            text-transform: capitalize;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            height: 100%;
        }
    
        .MuiSelect-select {
            width: 100% !important;
        }
    
        .MuiSelect-select,
        .MuiSelect-select:focus,
        .disp-selection-box {
            height: 100%;
            width: 100%;
            background-color: transparent !important;
            border-radius: 3px;
            border: 1px solid rgba(241, 242, 242, 0.30) !important;
    
            span {
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
    
            img {
                height: 20px;
                width: 30px;
            }
        }
    
        .disp-selection-box {
            width: 300px;
        }
    }
}

.newsletter-success {
    color: green;
    padding-left: 16px;
}
.newsletter-error {
    color: red;
    padding-left: 16px;
}

//footer css
@media (max-width: 1536px) {
    .footer .footer-grid .MuiGrid-container .MuiGrid-root:nth-child(5) {
        border-right: unset !important;
    }
}
@media (max-width: 1200px) {
    .footer {
        .contact-grid {
            .cont-title {
                font-size: 20px;
            }
        }
    }
}
@media screen and (min-width:1024px) and (max-width:1366px){
    .footer{
        .footer-grid {
            .MuiGrid-container {
                gap: 30px 0px;
                .MuiGrid-root{
                    &:not(:last-child) {
                        padding: 0px 28px;
                    }
                    &:nth-child(4) {
                        border: unset;
                    }
                    &:nth-child(5) {
                        border-right: 1px solid #5F5E5E !important;
                    }
                }
                .newsletter{
                    padding-left: 23px;
                }
            }
            .footer-list-title {
                font-size: 21px;
            }
           
        }
       
    }
   
}

@media (max-width: 900px) {
    .footer {
        .contact-grid {
            .cont-title {
                font-size: 18px;
            }
        }
        .footer-grid {
            .footer-list-title {
                font-size: 20px;
            }
            .MuiGrid-container .MuiGrid-root:not(:last-child) {
                border-right: unset;
            }
        }
        .bottom-footer {
            .css-1vs1y1p-MuiStack-root>:not(style)~:not(style) {
                margin-left: 0px !important;
            }
            .MuiPaper-root{
                margin-left:0px !important;
                &:not(:last-child){
                    margin-bottom: 10px !important;
                }
            }
        }

        .disp-selection-box {
            margin: 0 auto;
            width: fit-content !important;
        }
        .payment-f-list {
            justify-content: center !important;
        }
    }
}

@media screen and (min-width:768px) and (max-width:1023px){
    .footer {
        .css-pxpt32 {
            padding-top: 15px;
        }
        .contact-grid{
            .MuiCardContent-root {
                padding: 37px 33px;
            }
        }
        .css-zoser8 {
            padding-top: 0;
        }
        .footer-grid{
            padding-top: 16px;
            padding-bottom: 17px;
            .MuiGrid-container {
                padding: 0px 19px;
                gap: 20px;
            }
            .MuiAccordionDetails-root.css-17chsey-MuiAccordionDetails-root{
                padding-left: 0;
            }
            ul {
                display: flex;
                flex-wrap: wrap;
                gap: 0px 11px;
                li{
                    padding: 0;
                }
            }
            .newsletter{
                li{
                    width: 100%;
                    &.footer-list-title{
                        margin-bottom: 10px;
                    }
                }
            }
            .mail-box {
                width: 49px;
                height: 37px;
                svg{
                    width: 31px;
                    height: 26px;
                }
            }
          
        }
        
        
    }
   
}

@media screen and (min-width:768px) and (max-width:899px){
    .footer {
        .footer-grid{
            .MuiGrid-container {
                padding: 10px;
    
                .MuiGrid-root {
                    &:not(:last-child) {
                        border-bottom: 1px solid #565555;
                        padding-bottom: 7px;
                    }
                    .css-2e9yo9-MuiButtonBase-root-MuiAccordionSummary-root{
                        padding: 0;
                        justify-content: space-between;
                        .MuiAccordionSummary-content.css-1betqn-MuiAccordionSummary-content{
                            order: 1;
                        }
                        .css-yw020d-MuiAccordionSummary-expandIconWrapper {
                            transform: rotate(90deg);
                        }
                    }
                }
            }
        }
        
        
    }
}

@media (max-width: 600px) {
    .footer {
        .contact-grid {
            .cont-title {
                font-size: 16px;
            }
        }
        .footer-grid {
            .footer-list-title {
                font-size: 17px;
            }

            .footer-list-text span {
                font-size: 14px;
            }

            .mail-box {
                width: 60px;
                height: 46px;
            }

            .f-input-box input {
                height: 28px;
                border-radius: 10px;
            }

            ul {
                padding-left: 0px !important;
            }
            .MuiGrid-container {
                padding: 10px;

                .MuiGrid-root {
                    &:not(:last-child) {
                        border-right: unset !important;
                        border-bottom: 1px solid #565555;
                        padding-bottom: 7px;
                    }
                    .css-2e9yo9-MuiButtonBase-root-MuiAccordionSummary-root{
                        padding: 0;
                        justify-content: space-between;
                        .MuiAccordionSummary-content.css-1betqn-MuiAccordionSummary-content{
                            order: 1;
                        }
                        .css-yw020d-MuiAccordionSummary-expandIconWrapper {
                            transform: rotate(90deg);
                        }
                    }
                }
            }
        }
        .sub-f-grid {
            .sub-f-title {
                font-size: 16px;
            }
            .sub-f-content {
                display: block;
                text-align: center;
            }
        }
    }
}

@media (max-width: 767px) {
    .footer {
        .css-pxpt32 {
            padding-top: 15px;
        }
        .contact-grid{
            .MuiCardContent-root {
                padding: 37px 33px;
            }
        }
        .css-zoser8 {
            padding-top: 0;
        }
        .footer-grid{
            padding-top: 16px;
            padding-bottom: 17px;
            .MuiGrid-container {
                padding: 0px 19px;
                gap: 20px;
            }
            .MuiAccordionDetails-root.css-17chsey-MuiAccordionDetails-root{
                padding-left: 0;
            }
            ul {
                display: flex;
                flex-wrap: wrap;
                gap: 0px 11px;
                li{
                    padding: 0;
                }
            }
            .newsletter{
                li{
                    width: 100%;
                    &.footer-list-title{
                        margin-bottom: 10px;
                    }
                }
            }
            .mail-box {
                width: 49px;
                height: 37px;
                svg{
                    width: 31px;
                    height: 26px;
                }
            }
            .MuiGrid-container {
                padding: 10px;
    
                .MuiGrid-root {
                    &:not(:last-child) {
                        border-bottom: 1px solid #565555;
                        padding-bottom: 7px;
                    }
                    .css-2e9yo9-MuiButtonBase-root-MuiAccordionSummary-root{
                        padding: 0;
                        justify-content: space-between;
                        .MuiAccordionSummary-content.css-1betqn-MuiAccordionSummary-content{
                            order: 1;
                            margin-left: 0;
                        }
                        .css-yw020d-MuiAccordionSummary-expandIconWrapper {
                            transform: rotate(90deg);
                        }
                    }
                }
            }
        }
       
        
    }
}

@media (max-width: 320px){
    .footer{
        .contact-grid{
            .whats-btn {
                font-size: 13px;
            }
        }
        .footer-grid {
            .f-input-box {
                input {
                    font-size: 14px;
                }
            }
        }
    }
   
    
    
}
