.topsell-machine-grid {
    .tm-inner-block{
        // border:1px solid red;
        padding:20px;
    }
    .MuiGrid2-root {
        .ts-card {
            padding: 20px;
            // border: 1px solid red;
            .MuiCardContent-root{
                .MuiTypography-root{
                    color: #3D3D3D;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }
        }

        &:nth-child(1) {
            .ts-card {
                background-color: #E1FAFF;
            }
        }

        &:nth-child(2) {
            .ts-card {
                background-color: #FFEFDB;
            }
        }

        &:nth-child(3) {
            .ts-card {
                background-color: #FFD9E7;
            }
        }

        &:nth-child(4) {
            .ts-card {
                background-color: #E8FFE8;
            }
        }

        &:nth-child(5) {
            .ts-card {
                background-color: #F6F2F2;
            }
        }

        &:nth-child(6) {
            .ts-card {
                background-color: #D6E4FF;
            }
        }
    }

}
.filter-drop{
    border:1px solid rgb(195, 195, 195);
}
.save-btn {
    text-transform: none !important;
}
.filter-chip.active{
        background-color: #10CDF2;
}
@media (max-width: 900px) {
    .topsell-machine-grid .MuiGrid2-root .ts-card {
        padding: 5px;
    }
  }

@media (max-width: 600px) {
    .topsell-machine-grid .MuiGrid2-root .ts-card {
        padding: 5px;
    }
  }

