.page-wrapper {
    background-color: #fafafa;
    // background-color: #ffffff;
}

a {
    text-decoration: none;
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Adjust to the desired height */
}

.MuiContainer-root {
    padding-left: 44px !important;
    padding-right: 44px !important;
}

//General css start
.section-space {
    padding-top: 35px;
    background-color: #fff;
}

.img-cover {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center left;
}

.list-unstyled {
    list-style: none;
}

.center-mode {
    display: flex;
    align-items: center;
    justify-content: center;
}

//General css end
.section-title {
    display: flex;
    justify-content: space-between;
    padding: 0px 16px;
    background-color: #fafafa;
    align-items: center;

    span {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 0px !important;
    }
}
.content-wrapper{
    position:relative;
    // border:1px solid red;
    height:100%;
    padding-bottom: 40px;
}

.lang-control .MuiSelect-select,
.lang-items {
    .store-selection-drop {
        display: flex;
        align-items: center;

        .flag-img {
            height: 20px;
            width: 30px;
            background-color: rgb(255, 255, 255);
            margin-right: 15px;
        }
    }
}

.top-offer-slider {
    .slide-img {
        height: 100%;
    }

    .react-multi-carousel-dot button {
        width: 30px;
        height: 9px;
        margin: 5px;
        background: #fff;
        border: 1px solid #000000;
        border-radius: 20px;
    }

    .react-multi-carousel-dot--active button {
        background: #000000 !important;
    }
}

.top-seller-grid-slider {
    ul li {
        .MuiPaper-root {
            height: 100%;
        }
    }
}

// category list slider css
.category-home-grid-slider {
    .category-box {
        display: block;
        color: #000000;
        text-align: center;

        .category-box-img {
            height: 126px;
            width: 126px;
            border-radius: 100%;
            padding: 5px;
            overflow: hidden;
            margin: 0 auto;
            margin-bottom: 10px;
            background-color: rgb(253, 235, 238);

            img {
                border-radius: 100%;
            }

            &.active {
                border: 2px solid #06b4d8;
            }
        }

        .category-box-title {
            span {
                font-size: 20px;
                font-weight: 700;
                white-space: wrap;
                color: #000;
            }
        }
    }
}

// Porduct list box
.jeb-cust-slide {

    .slider-img-box {
        position: relative;
        height: 280px;
        width: 100%;

        // .offer-main-box {
        //     position: absolute;
        //     top: 30px;
        //     width: 100%;

        //     .offer-space-box {
        //         position: relative;
        //         display: flex;
        //         justify-content: space-between;
        //     }

        //     .seller-box {
        //         background-color: #118fa7;
        //         padding: 5px 8px;
        //         border-top-right-radius: 3px;
        //         border-bottom-right-radius: 3px;

        //         >div {
        //             font-size: 16px;
        //             color: #ffffff;
        //             font-weight: 600;
        //         }
        //     }

        //     .offer-box {
        //         padding: 5px 8px;
        //         border-top-left-radius: 3px;
        //         border-bottom-left-radius: 3px;
        //         background-color: #ffe0e4;

        //         >div {
        //             color: #b10647;
        //             font-weight: 600;
        //             font-size: 14px;
        //         }
        //     }
        // }
    }

    .top-sell-title {
        font-size: 16px;
        font-weight: 400;
        color: #000000;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .top-sell-amount {
        color: #000000;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
    }

    .top-actual_amount {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        font-weight: 300;
    }

    .top-vat {
        color: #000000;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
    }

    .color-switch-list {
        margin-bottom: 5px;

        button {
            min-width: fit-content !important;
        }

        .color-switch {
            display: flex;
            align-items: center;
            color: #000;
            font-size: 16px;
            font-weight: 400;
            height: 15px;
            width: 15px;
            border-radius: 100%;
        }
    }
    .stars-block {
        display: flex;
        gap: 4px;
    
        .stars-count {
            span {
                font-weight: 600;
            }
        }
    }
}

//brand grid
.brand-grid {
    .brand-img-box {
        padding: 5px 15px;
        @extend .center-mode;
        height: 100%;
    }
}

//Details page css
.breadcrumb-section{
    .breadcrumb-data{
        li{
            a{
                color:#3D3D3D;;
            }
        }
    }
}
// .right-img-tab {
//     .MuiBox-root {
//         overflow: hidden;
//     }
// }

.delivr-status-msg {
    width: 100%;
}

.wish-list-btn {
    border-radius: 5px;
    border: 1px solid #E7E5E5;
    background: #F8F4F4;
    padding: 10px;
}

.show-more-link {
    color: #3D3C3C;
    cursor: pointer;
    padding: 3px;
    font-size: 14px;
    font-weight: 500;
}

.description-text {
    margin-bottom: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.description-text.expanded {
    -webkit-line-clamp: unset;
}

.right-tabs {
    .tab-box {
        height: 146px;
        height: 100px;

        // border:1px solid red;
        img {
            height: 100%;
            width: 100%;
        }
    }
    .css-8je8zh-MuiTouchRipple-root{
        display: none !important;
    }
    
}

.custom-img-tab {
    .tab-box {
        height: 110px;
        width: 80px;
        // border:1px solid red;
    }
}

.details-center-content {
    h3 {
        color: #000;
        // font-family: 'Poppins', sans-serif;
        font-size: 25px;
        font-weight: 700;

    }

    .dtop-img {
        img {
            width: 130px;
            height: 100%;
        }
    }

    .rating-box {
        gap: 4px;
        display: flex;
        align-items: center;

        .MuiChip-root {
            color: #FFF;
            // font-family: 'Poppins', sans-serif;
            font-size: 18px;

            font-weight: 500;

        }

        span.total-rating {
            color: #5B5858;
            font-size: 18px;
            font-weight: 500;

        }
    }

    .amount-box {
        h4 {
            color: #000;
            font-size: 30px;
            font-weight: 700;

            label {
                color: #3D3D3D;
                font-weight: 400;
            }
        }
        .amount-now, .amount-was {
            display: flex;
            align-items: center;
            gap: 13px;
            margin-bottom: 10px;
            label{
                font-size: 16px;
            }
            h4 {
                font-size: 22px;
            }
        }
        .amount-was{
            label:last-child{
                text-decoration: line-through;
            }
        }
    }

    .stock-msg {
        label {
            color: #088A05;
        }
    }

    .delivr-status-msg {
        label {
            color: #5B5858;
            font-size: 14px;
        }

        span {
            color: #1082C2;
            font-size: 14px;
            font-weight: 500;
        }
    }

    label {
        font-size: 18px;
        font-weight: 500;
    }

    .qty-box {
        width: fit-content;

        label {
            color: #000;
        }
    }

    .add-cart-grp {
        display: flex;
        gap: 16px;

        button {
            font-size: 18px;
        }
    }

    .color-name-block {
        span {
            color: #000;
            font-size: 18px;
            font-weight: 500;

        }
    }

}

.prod-description li {
    font-size: 14px;
    font-weight: 400;
    color: #3D3C3C;
}

.cust-tabs-list {
    margin-bottom: 15px;

    .MuiTabs-flexContainer {
        gap: 6px;

        button {
            border-radius: 10px;
            background: #F6F2F2;
        }
    }

    .MuiTabs-indicator {
        border-radius: 10px;
        border: 1px solid #1082C2;
        top: 0;
        height: 100%;
        background-color: transparent;
    }

    .MuiTabs-vertical {
        .MuiTabs-indicator {
            left: 0;
            width: 100%;
        }
    }

    &.right-img-tab {
        height: 100%;

        [role="tabpanel"] {
            width: 100%;
            background-color: #E7E5E5;
            border-radius: 10px;
            border: 1px solid #E7E5E5;
            background: #F8F4F4;
            margin: 0px 15px;

            .MuiBox-root {
                height: 100%;
                @extend .center-mode;
            }
        }
        .cust-tabs-view-img {
            position: sticky;
            top: 10px;
        }

    }
}

.details-right-content {
    .list-content {
        .MuiTypography-root {
            color: #5B5858;
            font-size: 18px;
            font-weight: 500;
            line-height: 21px;

            .MuiLink-root {
                color: #1082C2;
                text-decoration-color: #1082C2;
            }
        }
    }

    .list-content-title {
        span {
            color: #5B5858;
            font-size: 18px;
            font-weight: 700;
            line-height: 21px;
            letter-spacing: unset;
        }

        p {
            color: #6E6B6B;
            font-size: 16px;
            font-weight: 400;
            line-height: 21px;
        }
    }
}

.add-cart-popup {
    z-index: 3 !important;
}

.top-cart-box {
    text-align: right;

    button {
        border-radius: 1.848px;
        background: #1082C2;
        text-transform: unset;
    }
}

ul.overview-content {
    margin-top: 0;

    li {
        color: #5C5959;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
    }
}

.overview-content{
    padding: 0px;
    li{
        
        .pagebuilder-column-group{
            flex-wrap: wrap;
        }
    }
    
}

.search-result {
    position: absolute;
    top: 42px;
    background-color: white;
    padding: 20px;
    right: 0;
    left: 0;
    border-radius: 5px;
    z-index: 1;
    height: 450px;
    color: #000;
    overflow-y: scroll;
}

// add to cart layout
// .overlay {
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background-color: rgba(0, 0, 0, 0.5);
//     /* Overlay color and transparency */
//     z-index: 1000;
//     /* Ensure the overlay appears on top of other content */
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }

// .MuiPopup-root.Mui-open {
//     z-index: 1001;
// }

.rmBtn{
    color: #00B4D7;
    font-size: 14px;
    text-decoration: underline;
}

.cart-top-wrapper {
    display: flex;
    gap: 15px;
    justify-content: space-between;
    align-items: start;
    padding-bottom: 10px;
}

.cart-content {
    padding-top: 10px;
    display: flex;
}

.qty-box {
    display: flex;
    gap: 10px;
    align-items: center;

    .qty-select {
        .MuiSelect-select {
            padding: 6px 10px;
        }

        .MuiSvgIcon-root {
            transform: unset !important;
        }
    }

    .MuiOutlinedInput-notchedOutline {
        border: unset !important;
    }
}
img{
    max-width: 100%;
    height: auto;
}
.rating-wrap {
    display: flex;
    align-items: center;
    gap: 6px;
    span.MuiCheckbox-root {
        svg{
            width: 18px;
            height: 18px;
        }
    }
    .progress-wrap {
        width: 250px;
      }
      .star-wrap{
        display: flex;
        align-items: center;    
        width: 35px;
        justify-content: space-between;   
      }
      &:nth-child(3) {
        .star-wrap {
            svg {
                color: #38ae04;
            }
        }
        .progress-wrap {
            .MuiLinearProgress-bar {
                background-color: #38ae04;
            }
        }
        
    }
    &:nth-child(4) {
        .star-wrap {
            svg {
                color: #82ae04;
            }
        }
        .progress-wrap {
            .MuiLinearProgress-bar {
                background-color: #82ae04;
            }
        }
    }
    &:nth-child(5) {
        .star-wrap {
            svg {
                color: #f3ac30;
            }
        }
        .progress-wrap {
            .MuiLinearProgress-bar {
                background-color: #f3ac30;
            }
        }
    }
    &:nth-child(6) {
        .star-wrap {
            svg {
                color: #f36c32;
            }
        }
        .progress-wrap {
            .MuiLinearProgress-bar {
                background-color: #f36c32;
            }
        }
    }
    &:nth-child(7) {
        .star-wrap{
            svg {
                color: #f36c32;
            }
        }
        .progress-wrap {
            .MuiLinearProgress-bar {
                background-color: #f36c32;
            }
        }
    }

  }
  .customer-reviews-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .customer-reviews-filter {
        display: flex;
        align-items: center;
        gap: 12px;
        .lang-filter {
            display: flex;
            gap: 12px;
            border: 1px solid;
            padding: 10px;
            border-radius: 8px;
            p {
                margin: 0;
            }
        }
      }
  }
  .review-start {
    border-right: 1px solid #f3f4f8;
  }
  .view-all-wrap {
    text-align: center;
    padding-top: 6px;
  }
.customer-reviews-list {
    padding: 24px 0;
    border-bottom: 1px solid #f3f4f8;
    &:last-child {
        border-bottom:none;
    }
    .customer-reviews-list-wrap {
        display: flex;
        align-items: flex-start;       
        .cust-name {
            padding: 2px 12px;
            h6 {
                margin-bottom: 0;
                font-weight: 900;
                font-size: 1rem;
                line-height: 20px;
                text-transform: capitalize;
                padding: 0;
            }
            p{
                margin: 0;
                color: #9ba0b1;
            }
        }
    
        .verified-user {
            display: flex;
            align-items: center;
            padding-left: 8px;
            border-left: 1px solid #f3f4f8;
            color: #3866df;
            span{
                display: contents;
            }
            svg {
                height: 18px;
            }
        }
    }
    .review-box {
        p{
            margin: 0;
        }
    }
}
.reviews-details {
    padding-left: 44px;
}
.total-ratings-count {
    margin: 0 0 4px;
}
@media (max-width: 1280px) { 
    .customer-reviews-wrap {
        h5 {
            font-size: 18px;
        }
    }
}
@media (max-width: 767px) { 
    .customer-reviews-wrap {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        .customer-reviews-filter {
            flex-direction: column;
            align-items: flex-start;
        }
    }
    .customer-reviews-list{
        margin: 0 -16px;
        .customer-reviews-list-wrap {
            flex-direction: column;
            .cust-name {
                padding: 2px 0;
            }
        }
    } 
    .reviews-details {
        padding-left:15px;
    }
}

//sidebar
.sidebar-drawer {
    .MuiListItemText-root {
        .MuiTypography-root {
            // color: #fff;
            color: #000;
            background-color: #fff;
        }
    }
    .MuiDrawer-paperAnchorLeft{
        top: 100px;
        .MuiBox-root  {
            &.css-or8kus{
                background-color: #fff !important;
            }
        }
       
    }
    .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop{
        background-color: transparent;
    }
    .MuiSvgIcon-fontSizeMedium{
        color: #000;
    }
}

//Login page layout
.toolbar-main {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.reg-thank-card {
    position: relative;
    padding: 30px 20px;
    text-align: center;
    background: linear-gradient(90deg, #05B8DC 0.21%, #5EE4FF 99.73%);
    color: #fff;

    .thank-reg-box {
        position: absolute;
        background-color: #FAFAFA;
        width: 850px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 4.535px;
        box-shadow: 0px 2.26727px 17.00452px 0px rgba(0, 0, 0, 0.25);
        bottom: -32%;

        .go-site-wrap {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}

//checkout page
.total-qty-box {
    .MuiInputBase-input.Mui-disabled {
        opacity: 1;
        -webkit-text-fill-color: black;
    }
}
.form-label{
    text-align: center;
    margin-bottom: 20px;
   label{
    font-size: 23px;
    line-height: 1.235;
    color: #000;
    font-weight: 700;
    
   }
}

span.pwd_visiblity {
    position: absolute;
    top: 59%;
    right: 24px;
    transform: translateY(-50%);
    cursor: pointer;
}

.form-lbl-grp {
    background-color: #F1F3F6;
    border: 0.25px solid #000;
    padding: 10px 20px;
    color: #2B2C2D;

    .MuiRadio-root {
        padding: 20px;
        width: 115px;
    }

    .MuiFormControlLabel-label {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        width: 100%;

        span {
            border-left: 1px solid #AAA4A4;
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.final-cart-details {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .item-lbl {
        color: #5C5B5B;
        font-size: 14px;
        font-weight: 500;
    }

    .item-val {
        color: #3A3838;
        font-size: 14px;
        font-weight: 700;
    }
}

.checkout-cont-wrap{
    .MuiBox-root.css-gg4vpm {
        div {
            color: #10cdf2;
        }
    }

}

//sign in modal
.register-modal{
    ul.MuiList-root{
        .login-wrapper{
            .reg-content-wrap{
                .MuiContainer-maxWidthMd {
                    max-width: 100%;
                    .css-binzgt {
                        margin-top: 0;
                    }
                    .css-1gzuic3-MuiGrid-root {
                        margin-left: 0;
                        width: 100%;
                    }
                    .css-1gzuic3-MuiGrid-root>.MuiGrid-item {
                        padding-left: 0;
                        max-width: 100%;
                        flex-basis: 100%;
                    }
                    .css-q96ekn-MuiPaper-root-MuiCard-root {
                        padding: 0;
                    }
                    h6{
                        font-size: 16px;
                        line-height: 25px;
                    }
                }
                
            }
    
        }
    
    }

}




//Add new address
.add-address-modal {
    .MuiCardContent-root {
        height: 650px;
        overflow-y: auto;
    }
    .MuiGrid-root.MuiGrid-container{
      margin-top:0px !important;
      .MuiPaper-root{
        // margin-bottom:0;
      }
    }

    .MuiGrid-root.MuiGrid-item {
        // display: flex;
        // justify-content: center;
        // align-items: center;
        // height: 100vh;

        // position: absolute;
        //     top: 50%;
        //     left: 50%;
        //     margin-right: -50%;
        //     transform: translate(-50%, -50%);
    }
}

//order table
.order-tbl-container {
    .MuiTableHead-root {
        .MuiTableRow-root th {
            border-top: 1px solid rgba(224, 224, 224, 1);
        }

        .MuiTableRow-root th:last-child {
            border-right: 1px solid rgba(224, 224, 224, 1);
        }

    }

    .MuiTableBody-root {
        .MuiTableRow-root {
            .MuiTableCell-root:last-child {
                border-right: 1px solid rgba(224, 224, 224, 1);
            }
        }
    }

    .css-2bmurj-MuiTableRow-root:last-child td,
    .css-2bmurj-MuiTableRow-root:last-child th {
        border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
        border-left: 1px solid rgba(224, 224, 224, 1);
    }
}
.dashboard-wrapper{
    background-color:'#fff';
}

//dashborad css start
.change_pwd-modal {
    .change_pwd_strength {
        word-break: break-all;
    }
}

//dashboard css end

.filter-box {
    .filter-btn {
        width: 100%;
        border: 1px solid #e1e1e1;

        &:hover {
            text-decoration: none;
            background-color: rgb(255, 255, 255);
            box-shadow: none;
        }

        .MuiButton-endIcon {
            position: absolute;
            right: 10px;
        }
    }
}

.filter-popver{
    .MuiPaper-root{
        right:0;
        // height:300px;
    }
}

//checkout page css start
.checkout-cont-wrap {
    .css-i087b1-MuiFormGroup-root {
        overflow: auto;
    }

}

.dash-content-wrap {
    .css-12zrijd-MuiButtonBase-root-MuiButton-root {
        color: #fff;
    }
}

//checkout page css end
// .demo-b{
//     border:1px solid red;
// }
// Responsive css
@media (max-width: 1399.98px) {
    .category-home-grid-slider .category-box .category-box-img {
        height: 110px;
        width: 110px;
    }
}

@media (max-width: 1200px) {
    .category-home-grid-slider .category-box .category-box-title span {
        font-size: 18px;
    }

    .checkout-cont-wrap {

        .css-4id84g {
            flex-wrap: wrap;

            .address-content {
                width: 100%;
                margin-bottom: 10px;

                .css-ojfi3k-MuiTypography-root {
                    line-height: 20px;
                    font-size: 17px;
                }
            }

            .css-17oy0k3-MuiButtonBase-root-MuiButton-root {
                font-size: 15px;
            }
        }
    }
}

@media screen and (min-width:1024px) and (max-width:1366px) {
    .checkout-cont-wrap {
        .css-4id84g {
            flex-wrap: wrap;

            .address-content {
                width: 100%;
                margin-bottom: 10px;

                .css-ojfi3k-MuiTypography-root {
                    line-height: 20px;
                    font-size: 17px;
                }
            }

            .css-17oy0k3-MuiButtonBase-root-MuiButton-root {
                font-size: 15px;
            }
        }

        .css-2g34zu-MuiCardContent-root:last-child {
            padding-bottom: 0;
        }

        .cart-content {
            flex-wrap: wrap;

            .css-j7qwjs {
                width: 100%;

                .css-t0my3o-MuiTypography-root {
                    font-size: 15px;
                      width: 100%;
                }

                .qty-box {
                    .css-f0eqg5 {
                        min-width: 87px;
                    }
                }

                .css-qn5rft-MuiTypography-root {
                    font-size: 14px;
                }
            }
        }

        .final-cart-details {
            padding-top: 15px;
        }

        button {
            font-size: 17px;
        }

        .css-i087b1-MuiFormGroup-root {
            gap: 5px;
            overflow: auto;
        }
    }

    //dashboard css start
    .dashboard-wrapper {
        .dash-content-wrap {

            //myorder-list css start
            .order-tbl-container {
                table {
                    td {
                        white-space: nowrap;
                    }
                }
            }

            .MuiTablePagination-root.css-jtlhu6-MuiTablePagination-root {
                width: 75%;
            }

            .MuiTablePagination-actions {
                display: flex;

                button {
                    width: auto;
                }
            }

            //myorder-list css end
        }
    }

    //dashboard css end

}

@media (max-width: 900px) {
    .section-title {
        span {
            font-size: 20px;
        }
    }

    .category-home-grid-slider .category-box .category-box-title span {
        font-size: 16px;
    }

    .jeb-cust-slide {
        .top-sell-title {
            font-size: 14px;
        }

        .top-sell-amount {
            font-size: 20px;
            line-height: 30px;
        }

        .top-actual_amount {
            font-size: 14px;
        }

        .top-vat {
            font-size: 12px;
        }
    }
}

@media screen and (min-width:768px) and (max-width:1023px) {
    .order-success-wrap {
        .css-fpy8yh {
            width: 100%;

            .css-1t09qn1-MuiCardContent-root {
                width: 100%;
            }
        }
    }

    //dashboard css start
    .dashboard-wrapper {
        .dash-content-wrap {

            //myorder-list css start
            .order-tbl-container {
                table {
                    td {
                        white-space: nowrap;
                    }
                }
            }

            .MuiTablePagination-root.css-jtlhu6-MuiTablePagination-root {
                width: 75%;
            }

            .MuiTablePagination-actions {
                display: flex;

                button {
                    width: auto;
                }
            }

            //myorder-list css end
        }
    }

    //dashboard css end

}

@media (max-width: 600px) {
    .section-title {
        span {
            font-size: 14px;
        }

        .MuiButtonBase-root {
            font-size: 12px;
            color: #000;
            padding: 2px 6px;
            margin-right: 5px;
        }
    }

    .MuiButtonBase-root {
        font-size: 10px;
        padding: 5px 5px;
    }

    .details-center-content {
        .add-cart-grp {
            button {
                font-size: 12px;
            }
        }

        h3 {
            font-size: 25px;
        }

        .amount-box {
            h4 {
                font-size: 24px;
            }
        }

        .delivr-status-msg {
            span {
                font-size: 16px;
            }
        }

        label {
            font-size: 16px;
        }
    }

    .details-right-content .list-content-title p {
        font-size: 14px;
    }

    .details-right-content .list-content-title span {
        font-size: 16px;
    }

    .details-right-content .list-content .MuiTypography-root {
        font-size: 16px;
    }

    ul.overview-content li {
        font-size: 14px;
    }

}

//registrtaion css start
@media (max-width: 767px) {
    //registration css start
    .reg-content-wrap{
        .MuiContainer-root {
            padding-left: 19px !important;
            padding-right: 19px !important;
            .css-binzgt {
                margin-top: 20px;
                .css-1r0923i-MuiCardHeader-root{
                    .MuiTypography-root {
                        font-size: 17px;
                        line-height: 27px;
                    }
                }
                .css-46bh2p-MuiCardContent-root {
                    padding: 10px 0px 0px;
                    .css-vh7uy5-MuiTypography-root {
                        font-size: 18px;
                        line-height: 27px;
                    }
                    label{
                        font-size: 14px;
                        font-weight: 500;
                    }
                    input{
                        padding: 12.5px 14px;
                    }
                    .pwd_strength{
                        height: 24px;
                        border-radius: 5px;
                        background: #C4F5FF;
                        color: #000;
                        font-family: Roboto;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        display: inline-block;
                        line-height: 24px;
                        padding: 0px 13px;
                    }
                    button{
                        text-transform: none;
                        box-shadow: unset;
                    }
                    .MuiGrid-item {
                        font-size: 13px;
                        line-height: 22px;
                    }
                }
            }
        }

        .MuiBox-root.css-69i1ev {
            gap: 8px;

            button {
                font-size: 13px;
            }
        }


    }


    //registration css end

    //home page css start
    .css-idvdqs-MuiCardContent-root:last-child {
        padding-bottom: 15px;
    }

    .category-home-grid-slider{
        .category-box {
            .category-box-img {
                width: 70.894px;
                height: 70.894px;
                margin-bottom: 7px;
            }
            .category-box-title {
                span {
                    font-size: 12px;
                }
            }
        }
    }

    // .react-multi-carousel-item {
    //     padding-bottom: 10px;
    // }

    .jeb-cust-slide {
        .top-sell-title {
            font-size: 14px;
            font-weight: 500;
        }
        .top-sell-amount {
            margin-bottom: 0;
            font-size: 14px;
            line-height: 23px;
            
        }
        .slider-img-box {
            // height: 118px;
            height: auto;
        }
    }

    //home page css end

    //filter css start
    .filter-box {
        position: relative;
        z-index: 9999;
        .filter-btn{
            .MuiButton-endIcon {
                right: 4px;
                border: 1px solid #DEDBDB;
                background: #EFEFEF;
                width: 35px;
                height: 38.5px;
                line-height: 38.5px;
                text-align: center;
                border-radius: 0px 5px 5px 0px;
                svg{
                    display: block;
                    text-align: center;
                    padding: 6px;
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
    .filter-popver {
        .css-g3hgs1-MuiBackdrop-root-MuiModal-backdrop{
            background-color:  rgba(16, 16, 16, 0.72);
        }
        .css-18orsqs-MuiButtonBase-root-MuiAccordionSummary-root{
            min-height: 22px;
            &.Mui-expanded {
                min-height: 22px !important;
                margin-bottom: 13px;
            }
            .css-o4b71y-MuiAccordionSummary-content {
                margin: 5px 0;
                .css-1ismtrt-MuiTypography-root {
                    font-size: 12px;
                    font-weight: 700;
                }
            }
        }
        .MuiGrid-item {
            border-bottom: 1px solid #EFEFEF;
            margin-bottom: 10px;
            .css-1pcnou9-MuiPaper-root-MuiAccordion-root:first-of-type {
                gap: 0;
                padding-bottom: 10px;
            }
            &:last-child{
                border-bottom: unset;
                margin-bottom: 0;
            }
        }
        .css-i74dtu-MuiAccordionDetails-root {
            padding: 8px 0px 0px;
            border: unset;
            .css-qjuj3r-MuiStack-root {
                gap: 10px 5px;
                a{
                    padding: 0;
                    background: #EFEFEF;
                    font-size: 11px;
                    font-weight: 500;
                    border-radius: 0;
                    margin: 0;
                    &:hover{
                        border-color:#5EE4FF;
                        background: #C8F6FF;
                    }
                 }
            }
            
        }
        .MuiChip-root {
            background: #EFEFEF;
            font-size: 11px;
            font-weight: 500;
            border-radius: 0;
            padding: 0px 9px;
        }
        .MuiBox-root {
            font-size: 14px;
            font-weight: 500;
            button{
                width: 41px;
                height: 30px;
                color: #fff;
            }
        }
        
   
    }
    //filter css end

    //domestic-machine css start
    .category_list {
        .MuiGrid2-root {
            padding-bottom: 0;
        }

    }

    //domestic-machine css end

    //checkout page css start
    .MuiContainer-root {
        padding-left: 19px !important;
        padding-right: 19px !important;
    }
    .checkout-cont-wrap{
        .css-1rfck18{
            width: 100%;
            margin-bottom: 30px;
            .css-th083e-MuiTypography-root {
                font-size: 17px;
               
            } 
        }

        .css-binzgt {
            margin-top: 40px;
        }

        .css-480o17-MuiGrid-root>.MuiGrid-item {
            padding-left: 23px;
        }
        .MuiGrid-spacing-xs-4 {
            > .MuiGrid-item {
                padding-right: 6px;
                position: relative;
                padding-top: 14px;
                .MuiPaper-root{
                    &:last-child{
                        margin-bottom: 0;
    
                    }

                }
                
            }
        }
        .css-4id84g{
            flex-wrap: wrap;
            .address-content{
                width: 100%;
                margin-bottom: 10px;
                .css-ojfi3k-MuiTypography-root {
                    line-height: 20px;
                    font-size: 17px;
                }
            }
            .css-17oy0k3-MuiButtonBase-root-MuiButton-root {
                font-size: 15px;
            }
        }
        .css-2g34zu-MuiCardContent-root:last-child {
            padding-bottom: 0;
        }
        .css-i087b1-MuiFormGroup-root {
            gap: 5px;
            overflow: auto;

            .css-1i1maw-MuiFormControlLabel-root {
                border: unset;
                border-radius: 7px;
                justify-content: space-between;
                .css-1hokeax-MuiButtonBase-root-MuiRadio-root {
                    order: 2;
                }
            }
        }

        .cart-content {
            flex-wrap: nowrap;

            .css-j7qwjs {
                width: 74%;
                .css-t0my3o-MuiTypography-root {
                    font-size: 15px;
                    width: 100%;
                }
                .MuiTypography-root {
                    font-size: 14px;
                    line-height: 20px;
                }
                .qty-box{
                    .css-f0eqg5 {
                        min-width: 87px;
                    }
                }
                .css-qn5rft-MuiTypography-root {
                    font-size: 14px;
                }
            }
        }
        .final-cart-details {
            padding-top: 15px;
        }
        
       
    }
    //checkoutpage css end

    //ordersuccess css start
    .order-success-wrap {
        .css-fpy8yh {
            width: 100%;

            .css-1t09qn1-MuiCardContent-root {
                width: 100%;
            }

            .css-1swdep6-MuiPaper-root-MuiCard-root {
                padding: 100px 0px;
            }

            .css-hk42mg-MuiTypography-root {
                font-size: 22px;
            }

            .css-1yd7aoe-MuiTypography-root {
                font-size: 14px;
                line-height: 22px;
            }

            .css-gvgi3v-MuiCardActions-root {
                a {
                    width: 100%;

                    button {
                        width: 100%;
                    }
                }
            }
        }
    }

    //ordersuccess css end

    //dashboard css start


    .dashboard-wrapper {
        >div{
            padding: 0;
        }
        .dash-content-wrap {
           

            //account-info css start
            .css-6uk0fl-MuiTypography-root {
                font-size: 14px;
            }

            .css-hvca0x-MuiGrid-root>.MuiGrid-item {
                padding-right: 0;
            }

            .css-15mc5mu {
                margin-bottom: 18px;
            }

            .css-1fkkker {
                padding: 4px 8px;

                .css-3rdfsn-MuiTypography-root {
                    font-size: 14px;
                }
            }

            .css-jzvwfm-MuiTypography-root {
                font-size: 12px;
                margin-bottom: 16px;
                word-break: break-all;
            }

            .css-ojfi3k-MuiTypography-root {
                font-size: 12px;
                line-height: normal;
            }

            .css-1ybp0r1 {
                margin-bottom: 14px;
            }

            .css-121x6jy-MuiCardActions-root {
                padding: 8px 16px;

                .css-9l27io-MuiButtonBase-root-MuiButton-root {
                    font-size: 12px;
                    text-decoration: underline;
                    text-align: left;
                    justify-content: flex-start;
                    padding: 0;
                }
            }

            .css-utgp04 {
                padding: 5px 15px;
                margin-top: 30px;
                margin-bottom: 17px;

                .css-f8bxr4-MuiTypography-root {
                    font-size: 14px;
                }
            }

            //account-info css end

            //newsletetr css start
            .newsletter_subscription{
                .css-1quyq81 {
                    padding: 8px 16px;
    
                    h6 {
                        font-size: 14px;
                    }
                }
    
                .css-1ismtrt-MuiTypography-root {
                    font-size: 14px;
                }
    
                button {
                    min-width: 157px;
                }
    
                .css-1nmk1ju {
                    margin-bottom: 8px;
                }
                .css-1djppr9 {
                    margin-bottom: 14px;
                    padding: 10px 16px;
                }
                .css-1r7uk83 {
                    margin-bottom: 0;
                }
    

            }
            button {
                border-radius: 0;
                word-break: break-all;
                line-height: 17px;
                font-size: 14px;
            }
            
            //newsletetr css end

            //mywishlishlist css start
            .wishlist-content{
                .css-1nmk1ju {
                    margin-bottom: 10px;
                }
            }
            //mywishlist css end

            //addressbook css start
            .address_book {
                .css-1nmk1ju {
                    margin-bottom: 13px;
                }

                .MuiBox-root.css-1fkkker {
                    margin-bottom: 5px;
                }

                .css-t0r4lf-MuiCardContent-root {
                    padding: 10px 16px;

                    p {
                        font-size: 14px;
                    }
                }

                .css-f8bxr4-MuiTypography-root {
                    word-break: break-all;
                }

            }

            //addressbook css end

            //myorder-list css start
            .order-tbl-container {
                table {
                    td {
                        white-space: nowrap;
                    }
                }
            }

            .MuiTablePagination-root.css-jtlhu6-MuiTablePagination-root {
                width: 75%;
            }

            .MuiTablePagination-actions {
                display: flex;

                button {
                    width: auto;
                }
            }

            //myorder-list css end

            //edit-address_book css start
            .edit-address_book {
                label {
                    font-size: 12px;
                    font-weight: 500;
                    color: #000;
                }

                input {
                    height: 33px;
                    padding: 2px 14px;
                }

                .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
                    border-color: #DDDADA;
                    border-radius: 0;

                }

                .css-mhc70k-MuiGrid-root>.MuiGrid-item {
                    padding-right: 0;
                    padding-bottom: 0;
                }

                .MuiBox-root.css-1fkkker {
                    margin-top: 20px;
                    margin-bottom: 0px;
                }

                .MuiGrid-item {
                    .css-19duy7y-MuiFormControl-root {
                        border: unset;
                    }

                }

            }

            //edit-address_book css end

        }
    }

    .change_pwd-modal {
        .css-1wnsr1i {
            width: 350px;
        }
    }

    //newsletter css end

    //dashboard css end

    //contact css start
    .contactpage {
        .titlehead {
            font-size: 14px;
            text-align: center;
            font-style: normal;
        }
    }

    //contact page css end
}

@media (max-width: 320px) {
    .reg-content-wrap {
        .MuiContainer-root {
            .css-binzgt {
                .css-1r0923i-MuiCardHeader-root {
                    .MuiTypography-root {
                        font-size: 14px;
                        line-height: 19px;
                    }
                }
            }
        }
    }

    .filter-popver{
        .MuiBox-root {
            width: 260px;
        }
        .MuiChip-root {
            padding: 0px 12px;
        }
    }

    .order-success-wrap {
        .css-fpy8yh {
            .css-hk42mg-MuiTypography-root {
                font-size: 18px;
            }

            .css-1yd7aoe-MuiTypography-root {
                font-size: 13px;
                line-height: 21px;
            }
        }
    }

    //dashboard css start

    //account-info css start

    .change_pwd-modal {
        .css-1wnsr1i {
            width: 320px;

            button {
                font-size: 12px;
            }
        }
    }

    //account-info css end

    //dashboard css end


}

// order view start
.dash-content-wrap {
    .order-wrapper {
        .css-1gjpycx-MuiPaper-root-MuiCard-root {
            border-radius: 0px;
            font-size: 14px;
        }

        .css-4kfzrb-MuiPaper-root-MuiCard-root {
            border-radius: 0px;
            font-size: 14px;
        }

        .css-ur2jdm-MuiContainer-root {

            // .css-hvca0x-MuiGrid-root>.MuiGrid-item{
            //     padding-left: 0px !important;
            //     padding-right: 0px !important;
            // }
            // .MuiBox-root.css-69i1ev{
            //     flex-wrap: wrap !important;
            // }
            .css-h4y409-MuiList-root {
                .css-1d4l5l2-MuiListItem-root {
                    padding: 0px;
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    font-size: 14px;

                    &:last-child {
                        span {
                            font-weight: 600;
                            font-size: 14px;
                        }
                    }

                    .css-tlelie-MuiListItemText-root:nth-child(2) {
                        display: flex;
                        justify-content: flex-end;
                        font-size: 14px;
                        font-weight: 500;

                    }

                }
            }
        }

    }
}

// order view End

@media (max-width: 767px) {
    // Start Customer Login 

    .login-wrapper {


        // .MuiContainer-root{
        //     padding: 0px !important;
        // }
        .css-46bh2p-MuiCardContent-root {
            padding: 16px 0px !important;
        }

        .css-1r0923i-MuiCardHeader-root {
            .MuiTypography-root {
                font-size: 18px;
            }

        }

        .css-vh7uy5-MuiTypography-root {
            font-size: 18px !important;
        }

        .css-mhc70k-MuiGrid-root>.MuiGrid-item {
            display: flex;
            flex-wrap: wrap !important;
        }

        form {
            .input-field-wrap {
                padding: 16px 0px 16px 16px !important;

                .input-field-label {
                    font-size: 14px;
                    font-weight: 500;
                    color: #414040;
                }
            }

            .form-sub-text {
                font-size: 14px;
            }
        }
    }
    // End Customer Login 

    // Start Details Page scss 
    .overview-content{
        .pagebuilder-column-group{
            flex-wrap: wrap;
        }
    }
    .details-center-content h3{
        font-size: 18px;
        color: #3A3939;
    }
    .details-center-content .amount-box h4{
        font-size: 18px;
        color: #444242;
    }
    .qty-box{
        .add-to-cart-btn{
            border-radius: 30px;
            background-color: #413F3F;
            color: #fff;
            box-shadow: none;
        }
    }
    .cust-tabs-list{
        .MuiTabs-scroller{
            overflow-x: auto !important;
        }
    }
    
    .pagebuilder-column{
        figure{
            img{
                width: 100%;
            }
        }
    }
    .right-tabs {
        .tab-box{
            height: 70px;
        }
    }
    .cust-tabs-list{
        .cust-tabs-view-img{
            height: 290px;
        }
    }

    .qty-box {
        .css-wm8w2k {
            flex-wrap: wrap;
        }

        .qty-select {
            .MuiSelect-select {
                background-color: #F6F4F4;
                border-radius: 30px;
                border: 0px;
            }
        }
        .css-19duy7y-MuiFormControl-root{
            border: 0px;
        }
    }
    .stock-msg{
        .delivr-status-msg{
            span{
                font-size: 15px;
                font-weight: 400;
            }
        }
    }
    .details-right-content{
        .rmBtn{
            color: #00B4D7;
            font-size: 14px;
            text-decoration: underline;
        }
    }
    .overview-box{
        background-color: #F6F6F6;
    }
    .dsc-block{
        background-color: #F6F6F6;
        margin-bottom: 15px;
    }
    
    .add-cart-popup {
        .open{
            .css-osq9aj{
                width: 100%;
                max-width: 255px;
            }
        }
        .MuiCardActions-root {
            button {
                padding: 4px 16px;
                font-size: 14px;
            }
        }
        .sub-total-box {
            .MuiTypography-root {
                font-size: 14px;
                line-height: 20px;
            }
        }
        
    }
    .cart-top-wrapper{
        .css-1yw919q-MuiTypography-root{
            font-size: 14px;
        }
    }
    .sub-total-box{
        .css-mfds6y-MuiTypography-root{
            font-size: 16px;
        }
    }
    .cart-content{
        flex-wrap: wrap;
        .css-yc6rxs{
            margin-bottom: 15px;
            width: 100%;
            height: 150px;
            
        }
        .css-t0my3o-MuiTypography-root{
            // font-size: 16px;
        }
        .MuiTypography-root {
            font-size: 14px;
            line-height: 20px;
        }
    }
   
    // End Details Page scss 

// Start Edit modal 
.add-address-modal{
    .css-m7r6nl-MuiGrid-root>.MuiGrid-item{
        padding: 0px;
        }
        .add-address-modal .MuiGrid-root.MuiGrid-container{
            margin-left: 0px;
        }
        .css-17ghnnh-MuiGrid-root{
            height: 100%;
        }
        .MuiGrid-grid-xs-5{
        width: 100%;
        max-width: 100%;
        }
        .MuiGrid-spacing-xs-8{
            margin-left: 0px;
        }
        .css-m7r6nl-MuiGrid-root{
            margin-top: 0px;
           
        }
        .css-m7r6nl-MuiGrid-root{
            width: 100%;
        }

        .css-1r0923i-MuiCardHeader-root {
            padding: 8px 12px;

            .css-1r0923i-MuiCardHeader-root .MuiTypography-root {
                font-size: 18px;
                line-height: 13px;
            }

        }

        .css-vh7uy5-MuiTypography-root {
            font-size: 19px;
        }

        .css-46bh2p-MuiCardContent-root {
            padding: 16px 0px 0px;

            .css-1koq54t-MuiFormLabel-root-MuiInputLabel-root {
                font-size: 14px;
                font-weight: 500;
            }
        }

        .css-178yklu {
            margin-top: 0;

            .css-mhc70k-MuiGrid-root>.MuiGrid-item {
                padding-right: 0;
            }
        }
}

// end Edit modal 


    // start payment-section 
    .payment-section {
        .css-m7r6nl-MuiGrid-root>.MuiGrid-item {
            padding: 0px;
        }

        .css-m7r6nl-MuiGrid-root {
            margin: 0px;
            width: 100%;

            .css-1ehb9mn-MuiGrid-root {
                flex-basis: 100%;
                max-width: 100%;
            }
        }

        .css-3wbd18-MuiTypography-root {
            font-size: 18px;
        }

        .css-1r0923i-MuiCardHeader-root .MuiTypography-root {
            font-size: 18px;
        }

        .css-binzgt {
            margin-top: 30px;
        }

        .css-46bh2p-MuiCardContent-root {
            padding: 0;

            .css-1koq54t-MuiFormLabel-root-MuiInputLabel-root {
                font-size: 14px;
                font-weight: 500;
            }

            .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
                border-radius: 5px;
                background: #F6F6F6;
                height: 48px;
                padding: 0px 14px;
            }
        }
    }

    // End payment-section 


    // start Edit account info 
    .dash-content-wrap {
        .css-15mc5mu {
            .css-6uk0fl-MuiTypography-root {
                font-size: 14px;
                font-weight: 500;
                line-height: 21px;
            }

        }

        .css-1fkkker {
            .css-3rdfsn-MuiTypography-root {
                font-size: 12px;
                font-weight: 500;
                line-height: 18px;
            }
        }

        .MuiInputLabel-root {
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;

        }

        .MuiFormControl-root {
            .MuiOutlinedInput-root {
                // margin-bottom: 15px;
                border-radius: 0px;

                .MuiInputBase-input {
                    padding: 8px 14px;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 18px;
                }
            }
        }

        .MuiFormControlLabel-label {
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
        }

        .css-mhc70k-MuiGrid-root>.MuiGrid-item {
            padding-top: 8px;
            font-size: 14px;
        }

        .css-12zrijd-MuiButtonBase-root-MuiButton-root {
            color: #fff;
        }
    }

    // Edit account info end 
    // start My Product Review 
    .dash-content-wrap {
        .order-tbl-container .MuiTableHead-root .MuiTableRow-root th {
            font-size: 14px;
            font-weight: 500;
        }

        .makeStyles-table-14 .MuiTableCell-root {
            font-size: 14px;
            font-weight: 400;
        }
    }

    // end My Product Review 
    // start Order view 
    .dash-content-wrap {
        .order-wrapper {
            .css-n5q157-MuiGrid-root>.MuiGrid-item {
                padding-right: 0px !important;
            }

            .css-4kfzrb-MuiPaper-root-MuiCard-root {
                border-radius: 0px;
            }

            .css-ur2jdm-MuiContainer-root {
                padding: 0px !important;
                margin: 0px;

                .css-hvca0x-MuiGrid-root>.MuiGrid-item {
                    padding-left: 0px !important;
                    padding-right: 0px !important;
                }

                .MuiBox-root.css-69i1ev {
                    flex-wrap: wrap !important;
                }

                .css-h4y409-MuiList-root {
                    .css-1d4l5l2-MuiListItem-root {
                        padding: 0px;
                        display: flex;
                        justify-content: space-between;
                        width: 100%;

                        &:last-child {
                            span {
                                font-weight: 600;
                                font-size: 14px;
                            }
                        }

                        .css-tlelie-MuiListItemText-root:nth-child(2) {
                            display: flex;
                            justify-content: flex-end;
                            font-size: 14px;
                            font-weight: 500;

                        }

                    }
                }
            }

        }
    }

    // End Order view
}
//Direction rtl
body.ar{
    .directionrtl {
        direction: rtl !important;
    }
}